<template>
  <div>
    <pro-document-breadcrumbs
      :breadcrumbs="[
        { name: 'menu.WebServiceErrorLog', icon: 'PROSmart-Docs' },
      ]"
      user-role="procurer"
    ></pro-document-breadcrumbs>
    <div style="width: 100%; padding: 10px">
      <q-card class="q-mb-md">
        <q-expansion-item
          :label="getRes('Document.SearchErrorLog')"
          header-class="text-h4"
          default-opened
        >
          <q-separator />
          <div class="q-pa-md">
            <div class="row q-pb-sm">
              <div class="col-2 cust-label-container">
                <label>{{ getRes("CustMenu.Col.DateCreated") }}</label>
              </div>

              <div class="col-6">
                <div class="row">
                  <div class="col">
                    <q-input
                      outlined
                      dense
                      hide-bottom-space
                      v-model="startDateTime"
                      :rules="['fullTime']"
                      :error="!!startDateTimeError"
                      :error-message="startDateTimeError"
                    >
                      <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            cover
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              v-model="startDateTime"
                              mask="YYYY-MM-DD HH:mm"
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  no-caps
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                      <template v-slot:append>
                        <q-icon name="access_time" class="cursor-pointer">
                          <q-popup-proxy
                            cover
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-time
                              v-model="startDateTime"
                              mask="YYYY-MM-DD HH:mm"
                              format24h
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  no-caps
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-time>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>

                  <div class="col-auto cust-label-container">
                    <label style="padding: 0 10px">{{ getRes("To") }}</label>
                  </div>

                  <div class="col">
                    <q-input
                      outlined
                      dense
                      hide-bottom-space
                      v-model="endDateTime"
                      :rules="['fullTime']"
                      :error="!!endDateTimeError"
                      :error-message="endDateTimeError"
                    >
                      <template v-slot:prepend>
                        <q-icon name="event" class="cursor-pointer">
                          <q-popup-proxy
                            cover
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              v-model="endDateTime"
                              mask="YYYY-MM-DD HH:mm"
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  no-caps
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-date>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                      <template v-slot:append>
                        <q-icon name="access_time" class="cursor-pointer">
                          <q-popup-proxy
                            cover
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-time
                              v-model="endDateTime"
                              mask="YYYY-MM-DD HH:mm"
                              format24h
                            >
                              <div class="row items-center justify-end">
                                <q-btn
                                  no-caps
                                  v-close-popup
                                  :label="getRes('System.Button.Close')"
                                  color="primary"
                                  flat
                                />
                              </div>
                            </q-time>
                          </q-popup-proxy>
                        </q-icon>
                      </template>
                    </q-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-end">
              <div class="col-auto">
                <q-btn
                  no-caps
                  color="primary"
                  :label="getRes('Search')"
                  @click="searchLog"
                />
              </div>
            </div>
          </div>
          <q-separator />
          <div class="q-pa-md" v-if="fullList.length > 0">
            <div class="row q-pb-sm">
              <div class="col-2 cust-label-container">
                <label>{{ getRes("Document.Ref") }}</label>
              </div>

              <div class="col-6">
                <q-input outlined dense v-model="ref" @input="filter"></q-input>
              </div>
            </div>
            <div class="row q-pb-sm">
              <div class="col-2 cust-label-container">
                <label>{{ getRes("Document.Subject") }}</label>
              </div>

              <div class="col-6">
                <q-input
                  outlined
                  dense
                  v-model="subjEng"
                  @input="filter"
                ></q-input>
              </div>
            </div>
          </div>
        </q-expansion-item>
      </q-card>
      <q-table
        :loading="loading"
        :data="data"
        :columns="columns"
        row-key="id"
        :pagination="{ rowsPerPage: 11 }"
        wrap-cells
      >
        <template v-slot:header-cell-download="">
          <q-th>
            <q-btn
              v-if="data.length > 0"
              no-caps
              dense
              round
              flat
              color="primary"
              icon="download"
              @click="handleDownload()"
            />
          </q-th>
        </template>

        <template #body="props">
          <q-tr
            :props="props"
            :key="`m_${props.row.id}`"
            @click="props.expand = !props.expand"
            :style="'cursor:pointer'"
          >
            <q-td style="width: 80px; max-width: 80px; text-align: center">
              <q-btn
                no-caps
                dense
                round
                flat
                color="primary"
                icon="download"
                @click.stop="handleDownload(props.row)"
              />
            </q-td>
            <q-td>
              <div v-text="props.row.traceId"></div>
            </q-td>
            <q-td>
              <div v-text="props.row.path"></div>
            </q-td>
            <q-td>
              <div v-text="props.row.processId"></div>
            </q-td>
            <q-td>
              <div
                v-text="
                  $proSmart.common.getFormattedDate(props.row.requestTime)
                "
              ></div>
            </q-td>
            <q-td>
              <div
                v-text="$proSmart.common.getFormattedDate(props.row.createTime)"
              ></div>
            </q-td>
          </q-tr>
          <q-tr
            v-show="props.expand"
            :props="props"
            :key="`e_${props.row.id}`"
            class="q-virtual-scroll--with-prev"
          >
            <q-td colspan="100%">
              <q-markup-table dense flat separator="none">
                <thead></thead>
                <tbody>
                  <tr class="q-tr--no-hover">
                    <td style="width: 200px">
                      {{ getRes("CustMenu.Field.Id") }}:
                    </td>
                    <td v-text="props.row.id" />
                  </tr>
                  <tr class="q-tr--no-hover">
                    <td style="width: 200px">
                      {{ getRes("CustMenu.Field.Method") }}:
                    </td>
                    <td v-text="props.row.method" />
                  </tr>
                  <tr class="q-tr--no-hover">
                    <td style="width: 200px">
                      {{ getRes("CustMenu.Field.Query") }}:
                    </td>
                    <td v-text="props.row.query" />
                  </tr>
                  <tr class="q-tr--no-hover">
                    <td style="width: 200px">
                      {{ getRes("CustMenu.Field.RequestBody") }}:
                    </td>
                    <td v-text="props.row.requestBody" />
                  </tr>
                  <tr class="q-tr--no-hover">
                    <td style="width: 200px">
                      {{ getRes("CustMenu.Field.ContentLength") }}:
                    </td>
                    <td v-text="props.row.contentLength" />
                  </tr>
                  <tr class="q-tr--no-hover">
                    <td style="width: 200px">
                      {{ getRes("CustMenu.Field.IP") }}:
                    </td>
                    <td v-text="props.row.ip" />
                  </tr>
                  <tr class="q-tr--no-hover">
                    <td style="width: 200px">
                      {{ getRes("CustMenu.Field.TenderId") }}:
                    </td>
                    <td v-text="props.row.tenderId" />
                  </tr>
                  <tr class="q-tr--no-hover">
                    <td style="width: 200px">
                      {{ getRes("CustMenu.Field.TenderRef") }}:
                    </td>
                    <td v-text="props.row.tenderRef" />
                  </tr>
                  <tr class="q-tr--no-hover">
                    <td style="width: 200px">
                      {{ getRes("CustMenu.Field.TenderSubject") }}:
                    </td>
                    <td v-text="props.row.tenderSubject" />
                  </tr>
                  <tr class="q-tr--no-hover">
                    <td style="width: 200px">
                      {{ getRes("CustMenu.Field.WorkflowCode") }}:
                    </td>
                    <td v-text="props.row.workflowCode" />
                  </tr>
                  <tr class="q-tr--no-hover">
                    <td style="width: 200px">
                      {{ getRes("CustMenu.Field.UserId") }}:
                    </td>
                    <td v-text="props.row.userId" />
                  </tr>
                  <tr class="q-tr--no-hover">
                    <td style="width: 200px">
                      {{ getRes("CustMenu.Field.ErrorType") }}:
                    </td>
                    <td v-text="props.row.errorType" />
                  </tr>
                  <tr class="q-tr--no-hover">
                    <td style="width: 200px">
                      {{ getRes("CustMenu.Field.ErrorMessage") }}:
                    </td>
                    <td v-text="props.row.errorMessage" />
                  </tr>
                  <tr class="q-tr--no-hover">
                    <td style="width: 200px">
                      {{ getRes("CustMenu.Field.ErrorAssemblyName") }}:
                    </td>
                    <td v-text="props.row.errorAssemblyName" />
                  </tr>
                  <tr class="q-tr--no-hover">
                    <td style="width: 200px">
                      {{ getRes("CustMenu.Field.StackTrace") }}:
                    </td>
                    <td>
                      <textarea
                        readonly
                        style="width: 1363px; height: 300px"
                        v-text="props.row.stackTrace"
                      >
                      </textarea>
                    </td>
                  </tr>
                </tbody>
              </q-markup-table>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs.vue";

export default {
  name: "WebServiceErrorLog",
  components: { ProDocumentBreadcrumbs },
  data() {
    return {
      startDateTime: null,
      endDateTime: null,
      endDateTimeError: "",
      startDateTimeError: "",
      loading: false,
      fullList: [],
      data: [],
      ref: "",
      subjEng: "",
    };
  },
  computed: {
    columns() {
      return [
        {
          name: "download",
          label: "",
          field: "download",
          sortable: false,
          align: "left",
        },
        {
          name: "traceId",
          label: this.getRes("CustMenu.Field.TraceId"),
          field: "traceId",
          sortable: true,
          align: "left",
        },
        {
          name: "path",
          label: this.getRes("CustMenu.Field.Path"),
          field: "path",
          sortable: true,
          align: "left",
        },
        {
          name: "processId",
          label: this.getRes("CustMenu.Field.ProcessId"),
          field: "processId",
          sortable: true,
          align: "left",
        },
        {
          name: "requestTime",
          label: this.getRes("CustMenu.Field.RequestTime"),
          field: "requestTime",
          sortable: true,
          align: "left",
        },
        {
          name: "createTime",
          label: this.getRes("CustMenu.Field.CreateTime"),
          field: "createTime",
          sortable: true,
          align: "left",
        },
      ];
    },
  },
  created() {
    this.startDateTime = this.$proSmart.common.format.dateTime(
      this.addMinutes(new Date(), -15)
    );

    this.endDateTime = this.$proSmart.common.format.dateTime(
      this.addMinutes(new Date(), 5)
    );
  },
  methods: {
    addMinutes(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    },
    searchLog() {
      this.endDateTimeError = "";
      this.startDateTimeError = "";

      let dateFrom = this.startDateTime;
      let dateTo = this.endDateTime;

      if (!dateFrom) {
        this.startDateTimeError = "Not empty";
        return;
      }

      if (dateFrom > dateTo) {
        this.endDateTimeError =
          "End DateTime to cannot be earlier than Start DateTime from";
        return;
      }

      this.loading = true;

      this.$proSmart.admin
        .GetWebServiceErrorLog(this, dateFrom, dateTo)
        .then((rs) => {
          this.loading = false;
          this.fullList = rs;
          this.filter();
        });
    },
    filter() {
      if (this.ref === "" && this.subjEng === "") {
        this.data = this.fullList;
        return;
      }

      this.data = this.fullList.filter((b) => {
        if (b.tenderRef === null) b.tenderRef = "";
        if (b.tenderSubject === null) b.tenderSubject = "";

        return (
          b.tenderRef.toLowerCase().includes(this.ref.toLowerCase()) &&
          b.tenderSubject.toLowerCase().includes(this.subjEng.toLowerCase())
        );
      });
    },
    getFileName() {
      let fileName = "";
      if (this.startDateTime) {
        fileName += this.$proSmart.common.getISODateString(this.startDateTime);
      }

      fileName += "_to_";

      if (this.endDateTime) {
        fileName += this.$proSmart.common.getISODateString(this.endDateTime);
      } else {
        fileName += this.$proSmart.common.getISODateString(new Date());
      }

      return fileName;
    },
    handleDownload(row) {
      if (!btoa) {
        this.$alert(
          this.getRes("ErrorMessage.Error"),
          "Javascript function 'btoa' not found!",
          { confirmButtonText: this.getRes("ok") }
        );
        return;
      }

      let downloadContext = row ? row : this.data;
      let fileName = row ? row.traceId : this.getFileName();

      this.$proSmart.download.processDownloadFile(
        fileName + ".txt",
        btoa(
          unescape(encodeURIComponent(JSON.stringify(downloadContext, null, 4)))
        ),
        "txt"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ {
  th,
  td {
    vertical-align: middle;
  }
}
</style>
